import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography } from 'antd';
import IconFileList from './IconFileList';
import IconDisplayGrid from './IconDisplayGrid';
import IconDisplay from './IconDisplay';
import './css/IconBrowserCard.css'; // Import the CSS file

const { Title } = Typography;

const IconBrowserCard = ({ onSelect, ImageTable, fileIndex, iconIndex }) => {
  const [localIconIndex, setLocalIconIndex] = useState(iconIndex);
  const [localFileIndex, setLocalFileIndex] = useState(fileIndex);
  const [currentImage, setCurrentImage] = useState(ImageTable[fileIndex]);
  const [imageFileList, setImageFileList] = useState([]);

  const [totalIcons, setTotalIcons] = useState(
    ImageTable[fileIndex].iconsPerRow * ImageTable[fileIndex].iconsPerColumn
  );

  useEffect(() => {
    setLocalIconIndex(iconIndex);
    setLocalFileIndex(fileIndex);
    setCurrentImage(ImageTable[fileIndex]);
    setTotalIcons(
      ImageTable[fileIndex].iconsPerRow * ImageTable[fileIndex].iconsPerColumn
    );
  }, [fileIndex, iconIndex]);

  useEffect(() => {
    if (!Array.isArray(ImageTable)) {
      setImageFileList([]);
      return;
    }
    const filelist = ImageTable.map((image) => image.src).filter(Boolean);
    setImageFileList(filelist);
  }, [ImageTable]);

  const handleFileSelect = (fileName) => {
    const fileIdx = ImageTable.findIndex((image) => image.src === fileName);
    if (fileIdx !== -1) {
      setLocalFileIndex(fileIdx);
      setLocalIconIndex(0);
      setCurrentImage(ImageTable[fileIdx]);
      setTotalIcons(
        ImageTable[fileIdx].iconsPerRow * ImageTable[fileIdx].iconsPerColumn
      );
    }
  };

  const handleIconSelect = (fileIdx, iconIdx) => {
    setLocalIconIndex(iconIdx);
    onSelect(fileIdx, iconIdx);
  };

  return (
    <Card className="icon-browser-card">
      <Row gutter={16}>
        {/* File List (Scrollable) */}
        <Col span={10}>
          <Card title="Icon Files" className="file-list-card">
            <div className="file-list-scroll">
              <IconFileList files={imageFileList} onSelect={handleFileSelect} />
            </div>
          </Card>
        </Col>

        {/* Right Side - Selected Icon and Grid */}
        <Col span={14}>
          {/* Selected Icon Display */}
          <Card title="Selected Icon" className="selected-icon-card">
            <IconDisplay
              imageTableIndex={localFileIndex}
              iconIndex={localIconIndex}
              displayWidth={64}
              displayHeight={64}
            />
          </Card>

          {/* Icon Grid */}
          <Card title="Icon Grid" className="icon-grid-card">
            {localFileIndex !== null && (
              <IconDisplayGrid
                iconFileIndex={localFileIndex}
                totalIcons={totalIcons}
                onIconSelect={handleIconSelect}
              />
            )}
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default IconBrowserCard;
