import React, { useState, useRef, useEffect } from 'react';
import { Button, Popover, Space, Typography } from 'antd'; // Ant Design components
import IconSelector from './IconSelector'; // Assuming IconSelector is in the correct path
import IconBrowserCard from './IconBrowserCard'; // Import IconBrowserCard component
import './css/IconDisplay.css';
import imageTable from '../json-data/images.json'; // Adjust the path if necessary

// Function to calculate all icon offsets
const calculateIconOffsets = (imageWidth, imageHeight, iconsPerRow, iconsPerColumn, padding) => {
  const iconSizeX = (imageWidth - padding) / iconsPerRow;
  const iconSizeY = (imageHeight - padding) / iconsPerColumn;
  const offsets = [];

  for (let row = 0; row < iconsPerColumn; row++) {
    for (let col = 0; col < iconsPerRow; col++) {
      const offsetX = col * iconSizeX + padding; // Add padding directly
      const offsetY = row * iconSizeY + padding; // Add padding directly
      offsets.push({ offsetX, offsetY });
    }
  }
  return offsets;
};

// Function to get offsets for a specific icon index
const getIconOffsets = (index, imageWidth, imageHeight, iconsPerRow, iconsPerColumn, padding) => {
  const iconOffsets = calculateIconOffsets(imageWidth, imageHeight, iconsPerRow, iconsPerColumn, padding);
  if (index >= 0 && index < iconOffsets.length) {
    return iconOffsets[index];
  }
  return { offsetX: padding, offsetY: padding }; // Start with padding if index is out of range
};

const IconDisplay = ({ imageTableIndex, iconIndex, displayWidth, displayHeight, passOnClick, iconEditable, onIconEdit }) => {
  const [popoverVisible, setPopoverVisible] = useState(false); // State to handle popover visibility
 
  const { Text, Title } = Typography;

  // Validate the imageTableIndex
  if (
    imageTableIndex === undefined ||
    imageTableIndex < 0 ||
    imageTableIndex >= imageTable.length
  ) {
    console.warn('Invalid imageTableIndex provided to IconDisplay:', imageTableIndex);
    return null;
  }

  const { src, imageWidth, imageHeight, iconsPerRow, iconsPerColumn, padding } = imageTable[imageTableIndex];

  // Get the offset for the specified icon index
  const { offsetX, offsetY } = getIconOffsets(iconIndex, imageWidth, imageHeight, iconsPerRow, iconsPerColumn, padding);

  // Calculate icon dimensions
  const iconWidth = imageWidth / iconsPerRow;
  const iconHeight = imageHeight / iconsPerColumn;

  // Adjust display dimensions if they are fractional
  let adjustedDisplayWidth = displayWidth;
  let adjustedDisplayHeight = displayHeight;

  if (displayWidth < 1) {
    adjustedDisplayWidth = iconWidth * displayWidth;
  }
  if (displayHeight < 1) {
    adjustedDisplayHeight = iconHeight * displayHeight;
  }

  const xScale = adjustedDisplayWidth / iconWidth;
  const yScale = adjustedDisplayHeight / iconHeight;

  // Get CSS variable for border color
  const rootStyles = getComputedStyle(document.documentElement);
  const bordercolor = rootStyles.getPropertyValue('--border-color-light').trim();

  // Define styles for the background image
  const backgroundStyle = {
    width: `${adjustedDisplayWidth}px`,
    height: `${adjustedDisplayHeight}px`,
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/${src})`,
    backgroundPosition: `${-offsetX * xScale}px ${-offsetY * yScale}px`,
    backgroundSize: `${imageWidth * xScale}px ${imageHeight * yScale}px`,
    border: `1px solid ${bordercolor}`,
    position: 'relative', // Position relative to allow the edit button to be placed
  };

  // Define container styles
  const containerStyle = {
    width: `${adjustedDisplayWidth}px`,
    height: `${adjustedDisplayHeight}px`,
    overflow: 'hidden',
    backgroundSize: `${adjustedDisplayWidth}px ${adjustedDisplayHeight}px`,
  };

  // Callback for selecting a new icon from IconSelector
  const handleIconSelect = (fileIndex,iconIndex) => {
    console.log('handleIconSelect', fileIndex, iconIndex);
    onIconEdit(fileIndex,iconIndex); // Call the parent callback with the new iconIndex
    setPopoverVisible(false); // Close the popover
  };

  const getPopoverContent = () =>
  {
      return <IconBrowserCard
      onSelect={handleIconSelect}
      ImageTable={imageTable}
      fileIndex={imageTableIndex}
      iconIndex={iconIndex}
      className='icon-browser-card'
    />
  }
  const handleOnOpenChange = (visible) => {
    console.log('popoverVisbleChange', visible);
   
    setPopoverVisible(visible);
  }

  const handleIconClick = (e) => {
    console.log('handleIconClick:icondisplay', e);

      setPopoverVisible(!popoverVisible); // Toggle the popover
      if (passOnClick)
      {
        passOnClick(); // Call the parent callback
      }
      else
        console.log('passOnClick not defined');
  };

  return (
    <div className="icon-container" style={containerStyle}>
      <div
        id='iconDisplay'
        className="icon-display"
        onClick={handleIconClick}
        style={backgroundStyle}
      />

      {iconEditable && (
        <Popover
          content={getPopoverContent}
          title={<Title level={3} className='popover-title custom-popover' id="iconSelector">Select Icon</Title>}
          trigger="click"
          open={popoverVisible}
          onOpenChange={handleOnOpenChange}
          className='custom-popover'
        />
      )}
    </div>
  );
};

export default IconDisplay;
