import React, { useState, useEffect } from 'react';
import { Select, Typography, Space, Grid } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRealmContext } from '../contexts/RealmProvider';
import { usePackageContext } from '../contexts/PackageProvider'; // Import the PackageContext
import { CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'; // Importing the required icons
import './css/InputField.css'; // Ensure the path is correct

const { Option } = Select;
const { Title, Text } = Typography;

const RealmInput = ({transform}) => {
  const { realmID, setRealmID } = useRealmContext();
  const { getSortedItems } = usePackageContext(); // Get the sorted items function from PackageContext
  const [inputValue, setInputValue] = useState(realmID);
  const navigate = useNavigate();
  const location = useLocation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const showAlways = (transform === 'no') ? true : false

  // Fetch the sorted list of realm items on component mount
  const realmItems = getSortedItems('realm', 'name', 'asc');

  useEffect(() => {
    // Sync inputValue with realmID on load
    setInputValue(realmID);
  }, [realmID]);

  const handleSelectChange = (value) => {
    setInputValue(value);
    if (realmID !== value) {
      setRealmID(value);
      updateURL(value);
    }
  };

  const updateURL = (newRealmID) => {
    const params = new URLSearchParams(location.search);
    params.set('realmID', newRealmID);
    navigate({ search: params.toString() }, { replace: true });
  };

  const renderIcon = () => {
    return realmID ? <CheckCircleOutlined /> : <PlusCircleOutlined />;
  };

  return (
    <Space direction='horizontal' align="center" className="realm-input-container">
      {(screens.lg) ?(
        <>
          <Title level={4} className='header-title center-element element-margined-nill'>{`Realm`}</Title>
            {realmItems.length > 1 ? (
              <Select
                className="small-select  element-margined-nill"
                popupClassName="small-select"
                value={inputValue}
                onChange={handleSelectChange}
                style={{ width: 200 }}
              >
                {realmItems.map((item) => (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            ) : (
              // Display text if there's only one realm item
              <div>
                <Text className="single-realm-text">
                  {realmItems[0]?.name || "No Realms Available"}
                </Text>
              </div>
            )}
        </>
      ): (
        <>
        { showAlways ? (
          <Space direction='vertical'>
            <Title level={2} className='header-title'>{`Realm`}</Title>
              {realmItems.length > 1 ? (
                <Select
                  className="small-select"
                  popupClassName="small-select"
                  value={inputValue}
                  onChange={handleSelectChange}
                  style={{ width: 200 }}
                >
                  {realmItems.map((item) => (
                    <Option key={item.name} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              ) : (
                // Display text if there's only one realm item
                <Text className="single-realm-text">
                  {realmItems[0]?.name || "No Realms Available"}
                </Text>
              )}
          </Space>) : (null)}
        </>
      )}
  </Space>
  );
};

export default RealmInput;
