import React, { useState } from 'react';
import { Space, Typography, Input, Button, Popover , List} from 'antd';
import D20SVG from './cf-icons/D20SVG'; // Icon for the random button
import IconDisplay from './IconDisplay'; // Adjust the import path as necessary
import { EllipsisOutlined, DownOutlined,CaretDownOutlined  } from '@ant-design/icons';
import "./css/IconifiedTitle.css";
import "../App.css";

const { Title } = Typography;
const { Text } = Typography;

const IconifiedTitle = ({ 
  title, 
  iconFileIndex, 
  iconIndex, 
  iconDisplaySize, 
  onChange, 
  onIconChange,
  titleSize = "medium", 
  titleEditable = false, 
  iconEditable = false,
  iconPosition = 'left', 
  randomDescriptionFunction = null, 
  randomTextFunction 
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isRandomReady, setIsRandomReady] = useState(false);
  const [randomNames, setRandomNames] = useState([]); // Initialize as an empty array
  const [editTitle, setEditTitle] = useState(title); // Initialize with the title prop
  const [popoverVisible, setPopoverVisible] = useState(false); // Controls popover visibility
  
  const sizeMap = {
    "extrasmall": 4,
    "small": 3,
    "medium": 2,
    "large": 1
  };
  const size = sizeMap[titleSize.toLowerCase()] || sizeMap["medium"];


  const handleInputChange = (e) => {
    setEditTitle(e.target.value); // Update editTitle with the input value
  };
  
  const handleSelectChange = (value) => {
     setEditTitle(value); // Update editTitle with the selected value
     onChange(value); // Submit the new title
  }


  const handleNameSelect = (value) => {
    setEditTitle(value); // Update editTitle with the selected value
    onChange(value); // Submit the new title
    setPopoverVisible(false); // Close the popover after selection
    setIsEditing(false); // Stop editing
    setIsRandomReady(false);
  };

  const handleIconSelect = (fileIndex, iconIndex) => {
    setEditTitle(title); // Reset the editTitle to the original title
    onIconChange( fileIndex, iconIndex); // Pass the selected file and icon index
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && onChange) {
      onChange(editTitle); // Submit the new title
      setIsEditing(false);
    } else if (e.key === 'Escape') {
      console.log('handleKeyDown', title);
      setEditTitle(title); // Revert to the original title on escape
      setIsEditing(false);
    }
  };

  const handleBlur = (e) => {
    if (
      !e.relatedTarget ||
      (e.relatedTarget.id !== 'randomSelect' &&
        e.relatedTarget.id !== 'randomButton' &&
        e.relatedTarget.id !== 'nameInput' )
    ) {
      console.log('stopping edit');
      onChange(editTitle); // Submit the new title
      setIsEditing(false);
      setRandomNames([]); // Clear the random names
      setIsRandomReady(false);
    }
  };

  const handleClick = (e) => {
    console.log('handleClick', e);
  
    // Prevent execution if the clicked element is inside an <li> with class ant-list-item
    if (e.target.closest('li.ant-list-item') || e.target.closest('span') || e.target.closest('button.pagination-button')) {
      return;
    }
  
    if (titleEditable && !isEditing && e.target.id !== 'iconDisplay' && e.target.id !== 'iconSelector') {
      setEditTitle(title); // Reset the editTitle to the original title
      setIsEditing(true);
    }
  };

  const handleIconClick = () => {
    console.log('handleIconClick');

  };

  const generateRandom = async () => {
    if (!randomDescriptionFunction) return;
    if (!randomTextFunction) return;

    const ValueList = await randomTextFunction(await randomDescriptionFunction());
    const values = Array.isArray(ValueList.randomText.text) ? ValueList.randomText.text : []; // Ensure it's an array
    
    setRandomNames(values); // Set random names only if it's an array
    setIsRandomReady(true); // Swap input with the select box
  };

  const iconifiedTitleProps = {
    id:'iconDisplay',
    imageTableIndex: iconFileIndex,
    iconIndex,
    iconEditable,
    displayWidth:  iconDisplaySize ? iconDisplaySize : 25,
    displayHeight: iconDisplaySize ? iconDisplaySize : 25,
    onIconEdit: handleIconSelect,
    passOnClick: handleIconClick,
    className: 'fab-card-header-icon'
    };
  return (

    <Space className="iconified-title" onClick={handleClick}>
      {iconPosition === 'left' ? (
        <IconDisplay  {...iconifiedTitleProps}
      
      /> ) : null}
      {isEditing ? (
        <Space>
          {/* Conditionally render Input or Select based on isRandomReady */}
          <Input
              id="nameInput"
              className="text-title-input"
              value={editTitle  }
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              autoFocus
            />
         {isRandomReady && (
            <Popover
              content={
                <List
                  dataSource={randomNames}
                  renderItem={(name) => (
                    <List.Item className='selectable-item' onClick={() => handleNameSelect(name)}>
                      {name}
                    </List.Item>
                  )}
                  className='custom-popover'
                />
              }
              title={<Title level={3} className='popover-title custom-popover' id="iconSelector">Select Name</Title>}
              trigger="click"
              open={popoverVisible}
              onOpenChange={setPopoverVisible}
            >
              <Button icon={<CaretDownOutlined />} className='primary random-button' onClick={() => setPopoverVisible(true)} />
            </Popover>
          )}

          {/* Button to generate random names */}
          {randomTextFunction && !isRandomReady && (
            <Button
              id='randomButton'
              className='primary random-button'
              onClick={() => generateRandom()}
              icon={<D20SVG width='40' height='40' />}
            />
          )}
        </Space>
      ) : (
        <Title level={size} className="custom-title iconified-title-text">{title}</Title>
      )}
       {iconPosition === 'right' ? (
      <IconDisplay {...iconifiedTitleProps} /> ) : null}
    </Space>
  );
};

export default IconifiedTitle;
