import React from 'react';
import { Modal, Form, Input } from 'antd';
import './css/attributeModal.css';

const AddQuestionModal = ({ isVisible, onAdd, onCancel }) => {
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields()
      .then((values) => {
        form.resetFields();
        onAdd({
          Label: values.label,
          Type: 'text',
          CurrentValue: '',
          Description: values.description || ''
        });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title="Add New Question"
      open={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className="custom-modal"
    >
      <Form
        form={form}
        layout="vertical"
        name="add_question_form"
      >
        <Form.Item
          name="label"
          label="Question Label"
          rules={[{ required: true, message: 'Please input the question label!' }]}
        >
          <Input placeholder="Enter question label" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
        >
          <Input.TextArea placeholder="Enter question description" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddQuestionModal; 