import ApiCaller from './ApiCaller';
import PortalConfiguration from '../configuration/config';

class ImageService {
  // Upload Image
  static async uploadImage(apiCaller, sessionID, imageFile, imageMetaData = {}) {
    try {
      // Validate MIME type before proceeding
      const allowedMimeTypes = ['image/png', 'image/webp'];
      if (!allowedMimeTypes.includes(imageFile.type)) {
        throw new Error('Invalid file type. Only PNG and WEBP are allowed.');
      }

      // Additional MIME validation by reading file content
      await this.validateFileContent(imageFile);
      console.log(imageFile);

      // 1. Request a pre-signed URL from the upload service
      const requestBody = {
        Filename: imageFile.name,
        FileSize: imageFile.size,
        PK_UserID: imageMetaData.userID, // Replace with actual user ID logic
        ...imageMetaData
      };

      const preSignedResponse = await apiCaller.callApi({
        authorization: sessionID,
        service: `/`,
        method: 'POST',
        body: requestBody
      });
      const { uploadURL, imageItem } = preSignedResponse;

      // 2. Use the pre-signed URL to upload the image to S3
      await this.uploadImageToS3(imageFile, uploadURL);

      return imageItem; // Return the image data from the initial request
    } catch (error) {
      console.error('useImageService uploadImage() failed:', error);
      throw error;
    }
  }

  static uploadImageToS3 = async (file, uploadURL) => {
    try {
      // Log the file to ensure it's correctly passed
      console.log('Uploading file:', file);
  
      // Perform the actual upload using the pre-signed URL
      const response = await fetch(uploadURL, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,  // Ensure the content type matches the file's MIME type
        },
        body: file,  // The file object is passed directly as the body
      });
  
      if (response.ok) {
        console.log('File uploaded successfully');
      } else {
        console.error('Upload failed:', response.statusText);
        throw new Error(`Upload failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error during upload:', error);
      throw error;
    }
  };

  // Validate file content using FileReader
  static async validateFileContent(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const arrayBuffer = reader.result;
        const uint8Array = new Uint8Array(arrayBuffer);

        // Check PNG signature (first 8 bytes should match PNG signature)
        const pngSignature = [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A];
        const isPng = pngSignature.every((byte, index) => uint8Array[index] === byte);

        // Check WEBP signature (RIFF format followed by WEBP)
        const webpSignature = [0x52, 0x49, 0x46, 0x46]; // 'RIFF' in ASCII
        const isWebp = webpSignature.every((byte, index) => uint8Array[index] === byte) &&
          String.fromCharCode(uint8Array[8], uint8Array[9], uint8Array[10], uint8Array[11]) === 'WEBP';

        if ((file.type === 'image/png' && isPng) || (file.type === 'image/webp' && isWebp)) {
          resolve();
        } else {
          reject(new Error('File content does not match its MIME type.'));  
        }
      };

      reader.onerror = () => {
        reject(new Error('Failed to read file for validation.'));
      };

      // Read the first few bytes to verify the file signature
      reader.readAsArrayBuffer(file.slice(0, 16));
    });
  }

  // Delete Image
  static async deleteImage(apiCaller, sessionID, deleteParams = {}) {
    try {
      // 1. Request to move the image to the quarantine bucket and delete it
      const response = await apiCaller.callApi({
        authorization: sessionID,
        service: `/`,
        method: 'POST',
        body: deleteParams
      });

      return response; // Assuming response confirms deletion
    } catch (error) {
      console.error('Error deleting image:', error);
      throw new Error(error.response?.data || { error: 'Failed to delete image' });
    }
  }

  // Download Image
  static async downloadImage(apiCaller, sessionID, downloadParams = {}) {
    try {
      // 1. Request the download URL using the provided parameters
      const response = await apiCaller.callApi({
        authorization: sessionID,
        service: `/`,
        method: 'POST',
        body: downloadParams
      });

      return response; // Return the pre-signed download URL
    } catch (error) {
      
      throw error;
    }
  }

  static downloadImageBlob = async function(downloadURL = {}) {
    try {
       
      // 2. Fetch the image as a Blob
      const fetchResponse = await fetch(downloadURL);
      if (!fetchResponse.ok) {
        throw new Error(`Failed to fetch the image. Status: ${fetchResponse.status}`);
      }
      const blob = await fetchResponse.blob();
  
      return blob; // Return the Blob object for the image
    } catch (error) {
      console.error('Error downloading image blob:', error);
      throw new Error(error.response?.data || { error: 'Failed to download image blob' });
    }
  };
}

export default ImageService;


// Implementation of downloadImageBlob in ImageService

  
// Usage Example in React Component
// import ImageService from './ImageService';
// import ApiCaller from './ApiCaller';
// import PortalConfiguration from './PortalConfiguration';
// 
// const apiCaller = new ApiCaller('https://' + PortalConfiguration.enviornmentPrefix + 'aoendpoint.com');
// 
// async function handleUpload(event, authorization) {
//   try {
//     const file = event.target.files[0];
//     const additionalParams = {
//       type: 'image',
//       service: 'example-service',
//       payload: {},
//       GSI_PK_SERVICEID: 'example-service-id',
//       GSI_SK_SERVICEOBJECTID: 'example-object-id'
//     };
//     const response = await ImageService.uploadImage(apiCaller, authorization, file, 'example-user-id', additionalParams);
//     console.log('Image uploaded successfully:', response);
//   } catch (error) {
//     console.error('Upload failed:', error);
//   }
// }
// 
// async function handleDownload(authorization) {
//   try {
//     const downloadParams = {
//       PK_UserID: 'example-user-id',
//       SK_ImageID: 'example-image-id'
//     };
//     const downloadURL = await ImageService.downloadImage(apiCaller, authorization, downloadParams);
//     console.log('Download URL:', downloadURL);
//   } catch (error) {
//     console.error('Download failed:', error);
//   }
// }
// 
// async function handleDelete(authorization) {
//   try {
//     const deleteParams = {
//       PK_UserID: 'example-user-id',
//       SK_ImageID: 'example-image-id',
//       GSI_PK_UNIQUEFILENAME: 'unique-filename',
//       GSI_PK_SERVICEID: 'example-service-id',
//       GSI_SK_SERVICEOBJECTID: 'example-object-id'
//     };
//     const response = await ImageService.deleteImage(apiCaller, authorization, deleteParams);
//     console.log('Image deleted successfully:', response);
//   } catch (error) {
//     console.error('Delete failed:', error);
//   }
// }
