import React, { useState, useMemo } from 'react';
import { List, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './css/IconSelector.css';

const IconFileList = ({ files, onSelect }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchText, setSearchText] = useState('');

  const handleSelect = (file) => {
    setSelectedFile(file);
    if (onSelect) {
      onSelect(file); // Pass full filename
    }
  };

  // Function to remove path and extension from filename
  const getFileNameWithoutExtension = (filepath) => {
    // Split on both forward and backward slashes to handle any path format
    const parts = filepath.split(/[/\\]/);
    // Get the last part (the filename with extension)
    const filenameWithExt = parts[parts.length - 1];
    // Remove the extension
    return filenameWithExt.split('.')[0];
  };

  // Filter files based on search text
  const filteredFiles = useMemo(() => {
    if (!searchText) return files;
    return files.filter(file => 
      getFileNameWithoutExtension(file)
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
  }, [files, searchText]);

  return (
    <div className="icon-selector">
      <Input
        placeholder="Search files..."
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={e => setSearchText(e.target.value)}
        className="search-input"
        style={{ marginBottom: '8px', width: '100%' }}
        allowClear
      />
      <List
        itemLayout="horizontal"
        dataSource={filteredFiles}
        className="file-list"
        style={{ width: '100%' }}
        renderItem={(file) => (
          <List.Item
            className={selectedFile === file ? 'selected' : ''}
            onClick={() => handleSelect(file)}
          >
            {getFileNameWithoutExtension(file)}
          </List.Item>
        )}
      />
    </div>
  );
};

export default IconFileList;
