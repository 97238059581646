import React, { useState } from 'react';
import { List, Spin, Space, Typography, Button } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import EmptyControlContent from './EmptyControlContent';
import QuestionCard from './QuestionCard';
import AddAttributeModal from './AddAttributeModal';
import AddQuestionModal from './AddQuestionModal';
import AttributeCard from './AttributeCard';
import { useRealmObjectContext } from '../contexts/RealmObjectProvider';
import { useAuthContext } from '../contexts/AuthProvider';
import { useAppContext } from '../contexts/AppProvider';
import './css/attributeModal.css';
import './css/ContextTab.css';

const { Title } = Typography;

const ContextTab = ({ questions, attributes, loading, handleQuestionChange, focusedInput, setFocusedInput, handleAddAttribute, handleDeleteAttribute }) => {
  const {realmObject, updateRealmObject} = useRealmObjectContext();
  const { userPermissionLevel } = useAuthContext();
  const { featureFlags } = useAppContext();
  const [isAttributeModalVisible, setIsAttributeModalVisible] = useState(false);
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);

  // Custom loading icon
  const antIcon = <LoadingOutlined style={{ fontSize: 10 }} spin />;

  const showAttributeModal = () => {
    setIsAttributeModalVisible(true);
  };

  const showQuestionModal = () => {
    setIsQuestionModalVisible(true);
  };

  const handleAttributeModalCancel = () => {
    setIsAttributeModalVisible(false);
  };

  const handleQuestionModalCancel = () => {
    setIsQuestionModalVisible(false);
  };

  const handleAttributeModalAdd = (newValue) => {
    handleAddAttribute(newValue);
    setIsAttributeModalVisible(false);
  };

  const handleQuestionModalAdd = (newQuestion) => {
    const updatedQuestions = [...questions, newQuestion];
    updateRealmObject('Questions', updatedQuestions);
    setIsQuestionModalVisible(false);
  };

  const handleDelete = (name) => {
    handleDeleteAttribute(name);

    // Trigger re-render by setting an empty array when the last item is deleted
    if (attributes.length === 1) {
      setFocusedInput([]); // Clear the focused input state
    }
  };

  const handleQuestionDelete = (label) => {
    const updatedQuestions = questions.filter(question => question.Label !== label);
    updateRealmObject('Questions', updatedQuestions);
  };

  const canEditTemplate = featureFlags.getUserFlag(userPermissionLevel, 'EDIT_OBJECT_TEMPLATE');

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div className="attributes-header">
        <Title level={3} className="custom-title element-margined-small-left">Attributes</Title>
        {realmObject && ( 
          <Button 
            type="primary" 
            icon={<PlusOutlined />} 
            onClick={showAttributeModal} 
            className="add-attribute-button"
          />
        )}
      </div>
      
      {attributes.length > 0 && (
        <List
          dataSource={attributes}
          className={`primary-text ${loading ? 'fade-out' : ''}`}
          renderItem={item => (
            <AttributeCard
              attribute={item}
              handleDelete={handleDelete}
            />
          )}
        />
      )}
      
      <AddAttributeModal 
        isVisible={isAttributeModalVisible}
        newName={attributes.length + 1}
        onAdd={handleAttributeModalAdd}
        onCancel={handleAttributeModalCancel}
      />

      <Title level={3} className="custom-title element-margined-small-left">Contextual Information</Title>

      <List
        className={`custom-list ${loading ? 'fab-panel-loading fade-out' : ''} tab-container`}
        itemLayout="vertical"
        loading={{ spinning: loading, indicator: <Spin indicator={antIcon} /> }}
        dataSource={questions}
        locale={{ emptyText: <EmptyControlContent /> }}
        renderItem={item => (
          <QuestionCard
            key={item.Label}
            item={item}
            inputValue={item.CurrentValue || ''}
            handleChange={handleQuestionChange}
            focusedInput={focusedInput}
            setFocusedInput={setFocusedInput}
            onDelete={handleQuestionDelete}
            canEdit={canEditTemplate}
          />
        )}
        footer={
          realmObject && canEditTemplate && (
            <div className="questions-footer">
              <Button 
                type="primary" 
                icon={<PlusOutlined />} 
                onClick={showQuestionModal} 
                className="add-question-button"
              >
                Add Question
              </Button>
            </div>
          )
        }
      />

      <AddQuestionModal
        isVisible={isQuestionModalVisible}
        onAdd={handleQuestionModalAdd}
        onCancel={handleQuestionModalCancel}
      />
    </Space>
  );
};

export default React.memo(ContextTab);
