import React, { useState, useEffect } from "react";
import { Row, Col, Button, Grid, Modal, Input, message } from "antd";
import "./css/StoryEditor.css";
import KeyValuesList from "./KeyValuesList";
import LogoutButton from "./logoutButton";
import RealmInput from "./RealmInput";
import PortalConfiguration from "../configuration/config";
import { useAuthContext } from "../contexts/AuthProvider";
import { useAppContext } from "../contexts/AppProvider";
import { usePackageContext } from "../contexts/PackageProvider";
import { PlusCircleOutlined } from "@ant-design/icons";
import ChangePasswordForm from "./ChangePasswordForm";
import "./css/UserStatusPanel.css";

const UserStatusPanel = ({ drawerVisible }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isPasswordFormVisible, setIsPasswordFormVisible] = useState(false);
  const [isRealmModalVisible, setIsRealmModalVisible] = useState(false);
  const [newRealmName, setNewRealmName] = useState('');
  const [isCreatingRealm, setIsCreatingRealm] = useState(false);
  const { changePassword, loggedIn, userPermissionLevel } = useAuthContext();
  const { featureFlags } = useAppContext();
  const { addRealm } = usePackageContext();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const onFinish = (values) => {
    setLoading(true);
    changePassword(values.newPassword, values.newPasswordCheck)
      .then(() => {
        setLoading(false);
        setIsPasswordFormVisible(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setIsPasswordFormVisible(false);
    setError(null);
  };

  const handleCreateRealm = async () => {
    if (!newRealmName.trim()) {
      message.error('Please enter a realm name');
      return;
    }

    setIsCreatingRealm(true);
    try {
      await addRealm(newRealmName.trim());
      message.success('Realm created successfully');
      setIsRealmModalVisible(false);
      setNewRealmName('');
    } catch (error) {
      message.error('Failed to create realm: ' + error.message);
    } finally {
      setIsCreatingRealm(false);
    }
  };

  useEffect(() => {
    if (!drawerVisible) {
      setError(null);
      setIsPasswordFormVisible(false);
      setIsRealmModalVisible(false);
    }
  }, [drawerVisible]);

  return (
    <>
      {loggedIn ? (
        <Row gutter={[16, 16]} style={{ padding: "16px" }}>
          {(!screens.lg) ? (
            <Col span={24}>
              <RealmInput transform='no'/>
            </Col>
          ) : null}
          <Col span={24}>
            <LogoutButton />
          </Col>

          {featureFlags.getUserFlag(userPermissionLevel, 'CREATE_REALM') && (
            <Col span={24}>
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => setIsRealmModalVisible(true)}
                className="status-panel-button"
              >
                New Realm
              </Button>
            </Col>
          )}

          <Col span={24}>
            {!isPasswordFormVisible && (
              <Button
                type="primary"
                onClick={() => setIsPasswordFormVisible(true)}
                className="status-panel-button"
              >
                Change Password
              </Button>
            )}
          </Col>

          {isPasswordFormVisible && (
            <Col span={24}>
              <ChangePasswordForm
                onFinish={onFinish}
                loading={loading}
                drawerVisible={drawerVisible}
                handleCancel={handleCancel}
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
            </Col>
          )}
        </Row>
      ) : (
        <></>
      )}
      {PortalConfiguration.env === "qa" && (
        <Col span={24}>
          <KeyValuesList />
        </Col>
      )}

      <Modal
        title="Create New Realm"
        open={isRealmModalVisible}
        onOk={handleCreateRealm}
        onCancel={() => {
          setIsRealmModalVisible(false);
          setNewRealmName('');
        }}
        confirmLoading={isCreatingRealm}
        className="new-realm-modal"
      >
        <Input
          placeholder="Enter realm name"
          value={newRealmName}
          onChange={(e) => setNewRealmName(e.target.value)}
          onPressEnter={handleCreateRealm}
        />
      </Modal>
    </>
  );
};

export default UserStatusPanel;
