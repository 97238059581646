import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import './primitive-app-styles.css'
import { AppProvider } from './contexts/AppProvider';
import { AuthProvider } from './contexts/AuthProvider';
import { PackageProvider } from './contexts/PackageProvider';
import AppRoutes from './Routers/AppRoutes';
import { RealmProvider } from './contexts/RealmProvider';
import { RealmObjectProvider } from './contexts/RealmObjectProvider';

const App = () => {

  console.log('Campaign Fabricator - App.js');
  return (
    <Router>
      <AppProvider>
        <AuthProvider>
          <PackageProvider>
                <AppRoutes />
          </PackageProvider>
        </AuthProvider>
      </AppProvider>
    </Router>
  );
};

export default App;
