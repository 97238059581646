import React, { useState, useEffect } from 'react';
import { Card, Tooltip, Typography, Input, Space, Button } from 'antd';
import { InfoCircleOutlined, DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import './css/QuestionCard.css';

const { Text, Title } = Typography;
const { TextArea } = Input;

const QuestionCard = ({ item, inputValue, handleChange, focusedInput, setFocusedInput, onDelete, canEdit }) => {
  const [tempValue, setTempValue] = useState(inputValue);
  const [isDeleteEnabled, setIsDeleteEnabled] = useState(false);

  // Synchronize tempValue with inputValue when inputValue changes
  useEffect(() => {
    setTempValue(inputValue);
  }, [inputValue]);

  const handleInputChange = (e) => {
    setTempValue(e.target.value);
  };

  const handleBlur = () => {
    if (tempValue !== inputValue) {
      handleChange(tempValue, item.Label);
    }
    setFocusedInput(null);
  };

  const handleDeleteClick = () => {
    if (isDeleteEnabled) {
      onDelete(item.Label);
    } else {
      setIsDeleteEnabled(true);
      // Auto-disable after 3 seconds
      setTimeout(() => {
        setIsDeleteEnabled(false);
      }, 3000);
    }
  };

  return (
    <Card 
      className="question-card-item question-card-label" 
      bordered={true} 
      title={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Space>
            <Title className="question-card-header" level={5}>{item.Label}</Title>
            <Tooltip title={item.Description || "No description"}>
              <InfoCircleOutlined className="question-card-icon" />
            </Tooltip>
          </Space>
          {canEdit && (
            <Tooltip title={isDeleteEnabled ? "Click to confirm deletion" : "Delete question"}>
              <Button
                type={isDeleteEnabled ? "primary" : "text"}
                danger={isDeleteEnabled}
                icon={isDeleteEnabled ? <CheckCircleOutlined /> : <DeleteOutlined />}
                onClick={handleDeleteClick}
                className={`delete-button ${isDeleteEnabled ? 'delete-enabled' : ''}`}
              />
            </Tooltip>
          )}
        </Space>
      }
    >
      <TextArea
        id={`${item.Label.trim()}-input`}
        className="custom-input"
        placeholder={item.defaultValue || "Let Fabricator decide! or Enter answers!"}
        value={tempValue}
        onChange={handleInputChange}
        onFocus={() => setFocusedInput(item.Label)}
        onBlur={handleBlur}
        autoSize={{ minRows: 3, maxRows: 5 }}
      />
    </Card>
  );
};

// Custom comparison function for memoization
const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.inputValue === nextProps.inputValue &&
    prevProps.focusedInput === nextProps.focusedInput &&
    prevProps.item.Label === nextProps.item.Label &&
    prevProps.item.Description === nextProps.item.Description &&
    prevProps.canEdit === nextProps.canEdit
  );
};

// Exporting memoized component
export default React.memo(QuestionCard, areEqual);
