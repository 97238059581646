import PortalConfiguration from '../configuration/config';
import { CampaignFabricatorAuthTokens } from '../configuration/AuthorizationTokens';

class FeatureFlags {
    constructor() {
        this.flags = this.initializeFlags();
        
    }

    // Table of flags with their allowed environments
    flagConfig = {
        CREATE_REALM:               ["dev"],  // Enabled in PROD, also in lower environments unless specified otherwise  
        CREATE_MULTIPLE_CAMPAIGNS:  ["prod","qa","dev"],  // Enabled in PROD, also in lower environments unless specified otherwise
        REALMOBJECT_SHARE_TAB:      ["prod","qa","dev"],  // Enabled in PROD, also in lower environments unless specified otherwise
        DEGREES_OF_SEPERATION:      ["prod","qa","dev"],  // Enabled in PROD, also in lower environments unless specified otherwise
        SOME_FEATURE_FLAG:          ["off"],   // Disabled in all environments
        ADD_IMAGE:                  ["prod","qa","dev"],  
        EDIT_OBJECT_TEMPLATE:       ["prod","qa","dev"],  // Enabled in all environments
        EXPORT_REALM_TO_DOCUMENT:   ["prod","qa","dev"],  // Enabled in all environments
        // Add more flags here
    };

    // Define which flags are available for each user type
    userFlagAccess = {
        [CampaignFabricatorAuthTokens.REALM_MASTER]: 'ALL', // All flags enabled
        [CampaignFabricatorAuthTokens.GAME_MASTER]: 'ALL',  // All flags enabled
        [CampaignFabricatorAuthTokens.ADVENTURER]: [        // Only specific flags enabled
            'ADD_IMAGE',
            'REALMOBJECT_SHARE_TAB'
        ]
    };

    getUserFlag(AuthorizationToken, flag) {
        if (!this.initialized) throw new Error("FeatureFlags not initialized");
        
        const userAccess = this.userFlagAccess[AuthorizationToken];
        
        // If user has ALL access, return the flag's value
        if (userAccess === 'ALL') {
            return this.flags[flag];
        }
        
        // If user has specific flags access, check if the flag is in their allowed list
        if (Array.isArray(userAccess) && userAccess.includes(flag)) {
            return this.flags[flag];
        }
        
        // If no specific access is defined or flag is not in allowed list, return false
        return false;
    }
    
    initializeFlags() {
        const { env } = PortalConfiguration;
        const enabledFlags = {};
        // Iterate over the flagConfig to determine which flags should be enabled
        Object.keys(this.flagConfig).forEach(flag => {
            const allowedEnvironments = this.flagConfig[flag];
            if (allowedEnvironments.includes(env)) {
                enabledFlags[flag] = true;
            } else {
                enabledFlags[flag] = false;
            }
        });
        this.initialized = true;
        return enabledFlags;
    }
    
    
    setFlag(name, value) {
        if (!this.initialized) throw new Error("FeatureFlags not initialized");
        this.flags[name] = value;
    }

    getFlag(name) {
        if (!this.initialized) throw new Error("FeatureFlags not initialized");
        return this.flags[name];
    }

    isFlagEnabled(name) {
        if (!this.initialized) throw new Error("FeatureFlags not initialized");
        return !!this.flags[name];
    }
}

export default FeatureFlags;
