import React, { useState } from 'react';
import { Button, Row, Col } from 'antd';
import IconDisplay from './IconDisplay';

const ICONS_PER_ROW = 5;  // 5 icons per row
const ROWS_PER_PAGE = 4;  // 4 rows per page
const ICONS_PER_PAGE = ICONS_PER_ROW * ROWS_PER_PAGE; // 20 icons per page

const IconDisplayGrid = ({ iconFileIndex, totalIcons, onIconSelect }) => {
  const [page, setPage] = useState(0);

  const totalPages = Math.ceil(totalIcons / ICONS_PER_PAGE);

  const handleNextPage = () => {
    setPage((prev) => (prev < totalPages - 1 ? prev + 1 : prev));
  };

  const handlePrevPage = () => {
    setPage((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleIconClick = (iconIndex) => {
    if (onIconSelect) {
      onIconSelect(iconFileIndex, iconIndex);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {/* Grid Container with Fixed Height */}
      <div style={{ minHeight: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {Array.from({ length: ROWS_PER_PAGE }).map((_, rowIndex) => (
          <Row gutter={[16, 16]} justify="center" key={rowIndex}>
            {Array.from({ length: ICONS_PER_ROW }).map((_, colIndex) => {
              const iconIndex = page * ICONS_PER_PAGE + rowIndex * ICONS_PER_ROW + colIndex;
              if (iconIndex >= totalIcons) return null;
              return (
                <Col span={24 / ICONS_PER_ROW} key={iconIndex}>
                  <IconDisplay
                    imageTableIndex={iconFileIndex}
                    iconIndex={iconIndex}
                    displayWidth={50}
                    displayHeight={50}
                    passOnClick={() => handleIconClick(iconIndex)}
                  />
                </Col>
              );
            })}
          </Row>
        ))}
      </div>

      {/* Pagination Controls (Fixed Position) */}
      <div style={{ marginTop: '20px', width: '100%', textAlign: 'center' }}>
        <Button className='pagination-button' onClick={handlePrevPage} disabled={page === 0} style={{ marginRight: '10px' }}>
          Previous
        </Button>
        <span style={{ margin: '0 10px' }}>
          Page {page + 1} of {totalPages}
        </span>
        <Button className='pagination-button' onClick={handleNextPage} disabled={page === totalPages - 1} style={{ marginLeft: '10px' }}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default IconDisplayGrid;
